import { motion } from "framer-motion";
import * as React from "react";

import * as postStyles from "./post.module.scss";

import classes from "../../utils/classes";
import { Link } from "gatsby";
import Meta from "../../components/meta";
import { NavContext } from "../../components/nav";
import AboutContainer from "../../components/about";

import shortIcon from "../../../content/image/short-icon.svg";

const ShortTemplate = ({ data, children, image, pageContext }) => {
  const { frontmatter } = data.javascriptFrontmatter;

  const navContext = React.useContext(NavContext);
  React.useEffect(() => {
    navContext.setNav([{ name: frontmatter.category.name, to: "/" }]);
  }, []);

  return (
    <motion.div
      style={{
        translateX: "-1%",
      }}
      initial={{ opacity: 0 }}
      variants={{
        enter: {
          translateX: 0,
          opacity: 1,
          transition: {
            duration: 0.4,
          },
        },
        exit: {
          opacity: 0,
          translateX: "-1%",
          transition: {
            duration: 0.2,
          },
        },
      }}
    >
      <Meta
        title={`Quentin Golsteyn | ${frontmatter.title}`}
        description={frontmatter.description}
        canonical={`https://golsteyn.com${pageContext.pagePath}`}
        datePublished={frontmatter.date}
        isPost
      />
      <section className={classes("section", postStyles.header)}>
        <div>
          {image || <img src={shortIcon} alt="" width={24} height={24} />}
          <p className={classes("h5", postStyles.header__category)}>
            {frontmatter.category.name}
          </p>
          <h1 className="title">{frontmatter.title}</h1>
          <p className={postStyles.header__meta}>
            By{"  "}
            <Link
              to="/about"
              className={classes(postStyles.header__metaHighlight)}
            >
              {frontmatter.author.firstName} {frontmatter.author.name}
            </Link>{" "}
            {frontmatter.meta}
          </p>
        </div>
      </section>
      <section className={classes("section--narrow", "content")}>
        {children}
      </section>
      <section className="section">
        <AboutContainer />
      </section>
      <section className="section">
        <h4>Read more</h4>
        <div className={postStyles.readMore}>
          {pageContext.previous && (
            <div>
              <Link
                className={classes("block", "no-underline", postStyles.post)}
                to={pageContext.previous.pagePath}
              >
                <b className="underline">{pageContext.previous.title}</b>
                <br />
                <span className="subtitle">
                  {pageContext.previous.subtitle}
                </span>
              </Link>
            </div>
          )}

          {pageContext.next && (
            <div>
              <Link
                className={classes("block", "no-underline", postStyles.post)}
                to={pageContext.next.pagePath}
              >
                <b className="underline">{pageContext.next.title}</b>
                <br />
                <span className="subtitle">{pageContext.next.subtitle}</span>
              </Link>
            </div>
          )}
        </div>
      </section>
    </motion.div>
  );
};

const wrapWithShortTemplate = (Component, image) => (props) =>
  (
    <ShortTemplate {...props} image={image}>
      <Component {...props} />
    </ShortTemplate>
  );

export default wrapWithShortTemplate;
