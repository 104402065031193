import * as React from "react";
import { graphql } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";
import wrapWithPostTemplate from "../../src/templates/post";
import Header from "../../src/images/header/keyboard.inline.svg";

export const frontmatter = {
  title: "Keyboard shortcuts on the web",
  subtitle: "What to consider when building a keyboard interface",
  meta: "Front-end • Posted October 31, 2020",
  author: "hello@golsteyn.com",
  category: "front-end",
  date: "2020-10-31T00:00:00.000Z",
};

const Keyboard = () => (
  <>
    <p>
      <b>
        Keyboard interfaces are an important part of any user-facing
        application.
      </b>{" "}
      The ability to use the keyboard can help surface frequent or more complex
      tasks to the user, thereby increasing productivity and accessibility.
    </p>
    <p>
      At the same time, we have to ensure that our application follow guidelines
      consistent with other applications and the platform running our interface.
      Failure to do so would result in applications that require additional
      learning time and are more error-prone.
    </p>
    <p>
      Therefore, deciding on which actions to make accessible to the user via
      keyboard shortcuts and the shortcuts to use are important UX
      considerations.
    </p>
    <p>
      <i>
        <b>Note:</b>
      </i>{" "}
      keyboard navigation, although relying on the keyboard inputs, has
      different implications than keyboard shortcuts. See the{" "}
      <a href="https://www.w3.org/TR/wai-aria-practices-1.1/#kbd_generalnavhttps://www.w3.org/TR/wai-aria-practices-1.1/#kbd_generalnav">
        ARIA authoring practices
      </a>{" "}
      for more information on keyboard navigation.
    </p>
    <h2>Considerations when designing a keyboard interface</h2>
    <p>
      When determining which shortcuts to add to an application, we can answer
      two high-level questions:
    </p>
    <ol>
      <li>
        <b>Which actions should be accessible via a keyboard shortcut?</b>{" "}
        Requires an understanding of the actions the user may take in a
        particular interface.
      </li>
      <li>
        <b>Which keyboard shortcut should I use for this particular action?</b>{" "}
        Requires an understanding of guidelines surrounding the design of
        keyboard interfaces.
      </li>
    </ol>
    <p>
      When deciding on which actions to assign keyboard shortcuts, we should
      include common actions (such as copy/cut/paste, open, save, close, etc.)
      and application-specific actions the user will need to access regularly.
    </p>
    <h3>Choosing keyboard shortcuts</h3>
    <p>
      When selecting which key combination to use for each action, we can answer
      the following three questions:
    </p>
    <ol>
      <li>
        <b>What is the standard key combination for a particular action?</b> For
        example, saving is commonly accessible via <kbd>Ctrl+S</kbd>.
      </li>
      <li>
        <b>What is the key combination found in similar applications?</b> In a
        text editor, <kbd>Ctrl+B</kbd> bolds text. Other text editors should
        reuse this pattern.
      </li>
      <li>
        <b>
          What is the key combination found in applications used alongside ours?
        </b>{" "}
        For example, consider how GitHub or Bitbucket share similar keyboard
        shortcuts to code editors such as Atom or VS Code.
      </li>
    </ol>
    <p>
      If designing a keyboard shortcut for functionality not found in existing
      applications, we should strive for shortcuts that are easy to remember and
      do not conflict with existing browser or operating system keyboard
      shortcuts.
    </p>
    <p>
      Mnemonics (such as using <kbd>Ctrl+U</kbd> for <u>U</u>nderline) can help
      make shortcuts easy to remember.
    </p>
    <p>
      It is also important to consider localization when designing keyboard
      shortcuts, as different languages and keyboard layouts may make certain
      key combination less preferable.
    </p>
    <h3>Modifier keys</h3>
    <p>
      Most keyboard shortcuts use one or more modifier keys. Used effectively,
      they can help organize keyboard shortcuts and allow for multiple actions
      to be linked to one key (consider the relationship between Undo and Redo,
      using <kbd>Ctrl+Z</kbd> and <kbd>Ctrl+Shift+Z</kbd> respectively).
    </p>
    <p>
      The modifier key to use for a particular action will depend on the
      platform: The modifier key to use for a particular action will depend on
      the platform:
    </p>
    <ul>
      <li>
        <b>Windows/Linux —</b> <kbd>Ctrl</kbd> for large-scale effects,{" "}
        <kbd>Shift</kbd> for actions that complement the existing standard
        shortcut. <kbd>Alt</kbd> and <kbd>⊞ Win</kbd> should be avoided as they
        are used extensively for operating system functions.
      </li>
      <li>
        <b>Apple —</b> <kbd>⌘ Command</kbd> should be used preferably,{" "}
        <kbd>Shift</kbd> for actions that complement the existing standard
        shortcut. <kbd>Option</kbd> should only be used when the{" "}
        <kbd>⌘ Command</kbd> modifier is already taken. <kbd>Ctrl</kbd> should
        be avoided as it is used extensively for operating system functions
      </li>
    </ul>
    <p>
      Modifier keys are not always necessary, however. Interfaces that do not
      have a strong text entry focus can avoid modifier keys entirely (for
      example, GitHub or Photoshop). This can help speed up access to certain
      actions.
    </p>
    <h2>Scope of keyboard shortcuts</h2>
    <h3>The distinction between application, browser and OS shortcuts</h3>
    <p>
      Web applications have to take into consideration three levels of scope
      inherent to their execution environment: the operating system, the
      browser, and the application itself.
    </p>
    <p>
      In contrast to pointer-based inputs (mouse or touch gestures), it is
      difficult for the user to determine at which scope a particular keyboard
      shortcut will act on.
    </p>
    <figure className="full raise">
      <StaticImage src="../image/keyboard/pointer-org.png" />
      <figcaption>
        For pointer inputs, the visual hierarchy can help users determine which
        part of the screen belongs to the operating system, browser, or
        application scope.
      </figcaption>
    </figure>
    <p>
      For pointer-based inputs, the visual hierarchy of UI elements on the
      screen will help the user identify what belongs to the operating system,
      the browser, or the application. This distinction is less clear for
      keyboard inputs. While the use of modifier keys can help label certain
      keyboard shortcuts as belonging to a certain scope (using the{" "}
      <kbd>⊞ Win</kbd> modifiers for all OS-level shortcuts on Windows for
      example), these are not always obvious to the user and may be difficult to
      discover.
    </p>
    <p>
      As an example, consider the difference between <kbd>⌘+←/→</kbd> and{" "}
      <kbd>Ctrl+←/→</kbd> on Mac. The first is a browser-level shortcut,
      allowing the user to navigate between tabs. The second is an OS-level
      shortcut allowing the user to navigate desktop views.
    </p>
    <h3>Avoiding keyboard shortcut conflicts</h3>
    <p>
      As an application author, we should avoid keyboard shortcuts that override
      browser or OS defaults or are similar to existing system shortcuts.
    </p>
    <p>
      Of course, as with everything when design web applications, we have to
      take into consideration the different platforms our application will run
      on. Keyboard shortcuts vary between operating systems and browsers and our
      applications should handle all these cases.
    </p>
    <h3>Keyboard shortcut scoping from within a web application</h3>
    <p>
      As a web application changes, the part of the user interface receiving
      keyboard input may change as a result. Dialogs, tree views, dropdown views
      may take priority in receiving keyboard inputs over the main application.
      The part of our interface that receives keyboard actions (eg. focus) needs
      to be clear to the user through some sort of visual indication.
    </p>
    <p>
      Besides, there are no native approach on the web to restrict keyboard
      inputs to only a part of our web application (in the case of dialogs for
      example). We are responsible for implementing this logic.
    </p>
    <p>
      It is important to ensure that keyboard shortcuts do not take different
      meaning in differerent parts of our application (for example{" "}
      <kbd>Ctrl+D</kbd> taking a different meaning across screens).
    </p>
    <h2>Discoverability of keyboard shortcuts</h2>
    <p>
      Applications usually provide a list of all keyboard shortcuts in a
      dedicated screen. However, other approaches exist to help introduce users
      to shortcuts as they interact with the application.
    </p>
    <figure className="raise">
      <StaticImage src="../image/keyboard/youtube-shortcuts.png" />
      <figcaption>
        Example of a keyboard shortcut help screen on YouTube. It can be
        accessed by pressing <kbd>?</kbd>.
      </figcaption>
    </figure>
    <figure className="raise">
      <StaticImage src="../image/keyboard/macos-shortcuts.png" />
      <figcaption>
        All macOS menus list keyboard shortcuts alongside each menu item.
      </figcaption>
    </figure>
    <figure className="raise">
      <StaticImage src="../image/keyboard/googlephotos-shortcut.png" />
      <figcaption>
        Google Photos suggests a keyboard shortcut if the users performs an
        action repeatedly.
      </figcaption>
    </figure>
    <p>
      In general, when identifying an approach to let the user discover keyboard
      shortcuts, we can consider the following guidelines:
    </p>
    <ul>
      <li>
        Aim for solutions that allow for discovery through mouse-based inputs
      </li>
      <li>Look for incremental discovery</li>
      <li>
        Provide an easily accessible cheat sheet of important keyboard shortcuts
      </li>
    </ul>
    <h2>Chords, modes, and palettes</h2>
    <p>
      Keyboard chords and modes are core to Emacs and Vim. They are both
      examples of approaches to make a large number of actions accessible from
      the keyboard.
    </p>
    <p>
      Chords (which consist of two keypresses to achieve a keyboard action) can
      be a powerful way to create more keyboard shortcuts using a common
      keyboard shortcut to start a chord sequence (<kbd>Ctrl-C</kbd> and{" "}
      <kbd>Ctrl-X</kbd> in Emacs).
    </p>
    <p>
      Modes are a Vim feature that assigns different keyboard shortcuts
      depending on which mode is currently active.
    </p>
    <p>
      Both these approaches are non-intuitive and require a significant amount
      of training from the user to learn the sequences for each keyboard action.
      Once the user becomes familiar with these interfaces, they can be a very
      effective way to expose a considerable number of actions with the
      keyboard. Chords and modes may only make sense in specialized applications
      where a large number of actions are available to the user at any point in
      time.
    </p>
    <figure className="raise">
      <StaticImage src="../image/keyboard/palette.png" />
      <figcaption>
        Command palette in VS Code. Notice that the palette also suggests
        keyboard shortcuts to the user.
      </figcaption>
    </figure>
    <p>
      Palettes can provide an alternative that strikes a balance between ease of
      use and completeness. Palettes allow the user to search and select actions
      through a search bar which is usually invoked via a keyboard shortcut.
      Both Vim and Emacs provide this capability, but they have appeared in
      other applications such as VS Code or Figma for example.
    </p>
    <h2>Customizability</h2>
    <p>
      In certain scenarios, allowing users to customize available keyboard
      shortcuts may make sense. Video games often allow users to modify keyboard
      mapping, to cater to the user's preferred gaming style, or support certain
      gaming hardware.
    </p>
    <p>
      For complex applications, customizability can allow the user to adapt the
      interface to their preference, making the interface easier to use. The
      ability to customize keyboard shortcuts adds complexity, and most
      applications do not have to provide this feature.
    </p>
    <h2>Resources</h2>
    <ul>
      <li>
        <a href="https://developer.apple.com/design/human-interface-guidelines/macos/user-interaction/keyboard/">
          Apple Human Interface Guidelines - Keyboard
        </a>
      </li>
      <li>
        <a href="https://docs.microsoft.com/en-us/previous-versions/windows/desktop/dnacc/guidelines-for-keyboard-user-interface-design">
          Microsoft Guidelines for keyboard user interface design
        </a>
      </li>
      <li>
        <a href="https://developer.gnome.org/hig/stable/keyboard-input.html.en">
          GNOME Keyboard input guidelines
        </a>
      </li>
      <li>
        <a href="https://www.w3.org/TR/wai-aria-practices-1.1">
          ARIA Authoring Practices
        </a>
      </li>
      <li>
        <a href="https://en.wikipedia.org/wiki/Table_of_keyboard_shortcuts">
          Wikipedia Table of keyboard shortcuts
        </a>
      </li>
    </ul>
  </>
);

export const query = graphql`
  query ($id: String) {
    javascriptFrontmatter(id: { eq: $id }) {
      frontmatter {
        author {
          email
          firstName
          name
        }
        category {
          name
        }
        meta
        subtitle
        title
        date
      }
    }
  }
`;

export default wrapWithPostTemplate(
  Keyboard,
  <Header alt="" className="hero_image" style={{ maxHeight: 300 }} />
);
